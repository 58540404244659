import { ExerciseExec } from "@fithub-shared/trainee/interfaces"
import { ExcDataCache } from "src/modules/fithub-shared/trainee/student-cache-classes"

export function cleanupWorkout() {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (key && key.endsWith("@values")) removeItem(key)
    }

    setItem("completed", [])
}

export function removeCachedById(wid: string) {
    removeItem(`${wid}@values`)
}

export function setItem(tag: string, value: any) {
    if (value !== undefined) localStorage.setItem(tag, JSON.stringify(value))
}

export function getItem<T>(tag: string): T | undefined {
    const value = localStorage.getItem(tag)

    if (value !== null) {
        try {
            return JSON.parse(value) as T
        } catch (error) {
            console.error(
                `Error parsing localStorage item for key "${tag}":`,
                error
            )
            return undefined
        }
    } else {
        return undefined
    }
}
export function removeItem(tag: string) {
    if (localStorage.getItem(tag) !== null) localStorage.removeItem(tag)
}

export function convertCachedToExec(wid: string): ExerciseExec[] {
    const cache = getItem<{ [key: string]: ExcDataCache }>(`${wid}@values`)
    if (!cache) return []

    // Reidratar os objetos da classe ExcDataCache
    return Object.keys(cache).map((key) => {
        const excData = cache[key]
        const rehydratedData = new ExcDataCache(excData.wexec)

        // Reidrata os dados (caso precise manipular mais dados, adicione mais lógica aqui)
        rehydratedData.data = excData.data
        rehydratedData.feedback = excData.feedback

        return {
            exercise: key,
            data: rehydratedData.data,
            feedback: rehydratedData.feedback,
            wexec: wid,
        }
    })
}
