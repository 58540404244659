import React, { useEffect, useState } from "react"
import {
    CommentsView,
    ExerciseTitle,
    Footer,
    FooterButton,
    NotStartedDiv,
    TitleButtons,
    TitleHeader,
    TrainingSheet,
} from "../styles"
import { AiOutlineExclamation } from "react-icons/ai"
import { BsCheck, BsX, BsExclamation } from "react-icons/bs"
import { FiAlertTriangle, FiSettings, FiX } from "react-icons/fi"
import { LuTimer } from "react-icons/lu"
import { RiSpeedMiniFill } from "react-icons/ri"
import { SiAlwaysdata } from "react-icons/si"
import Timer from "src/components/timer"
import { GetMask, CommentInterface, GetRepsPlaceholder } from "../utils"
import { WeightExercise } from "@fithub-shared/interfaces/exercise"

import _reps from "src/modules/fithub-shared/json/reps.json"
import _cadency from "src/modules/fithub-shared/json/cadency.json"
import _interval from "src/modules/fithub-shared/json/interval.json"
import _method from "src/modules/fithub-shared/json/methods.json"
import ExerciseInput from "../components/ExerciseInput"
import YoutubeView from "../components/YoutubeView"
import useModal from "src/components/Modal"
import ModalEquipament from "../ModalEquipament"
import ModalFeedback from "../ModalFeedback"
import { IoChatboxEllipses } from "react-icons/io5"
import BisetComponent from "../../card-view/BisetComponent"
import { MdCheckCircle, MdCheckCircleOutline } from "react-icons/md"
import { ExerciseExec } from "@fithub-shared/trainee/interfaces"
import { useCachedContentN } from "./hooks/ncachedContent"
import ClickableIcon from "src/components/clickable-icon"
import { HiChevronLeft } from "react-icons/hi"

// import { Container } from './styles';

const Content: React.FC<{
    exercise: WeightExercise
    hint?: ExerciseExec
    started: boolean
    currentId?: string
    dismiss(): void

    onValidate: (id: string, valid: boolean) => void
}> = ({ exercise, started, hint, currentId, onValidate, dismiss }) => {
    const [comment, setComment] = useState<CommentInterface>()
    const [valid, setValid] = useState(false)
    const [exc, setExc] = useState<WeightExercise>(exercise)

    const series = exc.values.series.value[0]

    const { onChangeReps, onChangeWeight, cache, setFeedback } =
        useCachedContentN(currentId ?? "fallback")

    const equipament = useModal()
    const feedback = useModal()

    useEffect(() => {
        setExc(exercise)
        SetComment(undefined)
    }, [exercise])

    useEffect(() => {
        checkValidation()
    }, [cache])

    function SetComment(_comment?: CommentInterface) {
        if (comment && comment && _comment?.title === comment?.title) {
            setComment(undefined)
        }
        setComment(_comment)
    }

    function DismissComment() {
        setComment(undefined)
    }

    function checkValidation() {
        const isValid = Array(series)
            .fill(0)
            .every((_, i) => {
                const series = cache.getExerciseData(exercise.linkId)?.data[i]
                const res =
                    series &&
                    series.reps !== undefined &&
                    series.weight !== undefined

                return series && res
            })
        setValid(isValid)
        onValidate(exercise.id, isValid)
    }

    function Validate(id: string, index: number) {
        const series = cache.getExerciseData(exercise.linkId)?.data[index]

        if (series) {
            if (series.reps === undefined && series.weight === undefined)
                return <BsX size={20} color="#f35" />
            else if (series.reps === undefined || series.weight === undefined)
                return <BsExclamation size={20} color="#ffe033" />
            else {
                return <BsCheck size={20} color="#3f5" />
            }
        } else {
            return <BsX size={20} color="#f35" />
        }
    }

    function WeightHint(index: number) {
        try {
            return hint && index > hint?.data.length - 1
                ? 0
                : hint?.data[index]?.weight
        } catch (err) {
            alert(err)
            return undefined
        }
    }

    function RepsHint(index: number) {
        try {
            return hint && index > hint?.data.length - 1
                ? 0
                : hint?.data[index]?.reps
        } catch (err) {
            alert(err)
            return undefined
        }
    }

    return (
        <div style={{ flexDirection: "column" }}>
            <TitleHeader>
                <div>
                    <ClickableIcon
                        onPress={dismiss}
                        icon={HiChevronLeft}
                        size={48}
                    />

                    <ExerciseTitle>{exc.name}</ExerciseTitle>
                    {started ? (
                        <>
                            {valid ? (
                                <MdCheckCircle color={"#3f5"} size={22} />
                            ) : (
                                <MdCheckCircleOutline
                                    color={"#aaa"}
                                    size={22}
                                />
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                </div>

                <div>
                    <TitleButtons onClick={equipament.openModal}>
                        <FiSettings color="black" size={16} />
                    </TitleButtons>
                    <TitleButtons onClick={feedback.openModal}>
                        <IoChatboxEllipses color="black" size={16} />
                    </TitleButtons>
                </div>
            </TitleHeader>

            <ModalEquipament
                linkid={exc.linkId}
                isOpen={equipament.isOpen}
                onClose={equipament.closeModal}
            />
            <ModalFeedback
                defaultValue={cache.getFeedback(exc.linkId)}
                isOpen={feedback.isOpen}
                onClose={(v) => {
                    setFeedback(exc.linkId, v)
                    feedback.closeModal()
                }}
            />

            <YoutubeView linkId={exc.linkId} />

            {started ? (
                <TrainingSheet>
                    <BisetComponent exercise={exc} />
                    <thead>
                        <tr>
                            <td>S</td>
                            <td>
                                <h4>REPETIÇÕES</h4>
                            </td>
                            <td>
                                <h4>PESO</h4>
                            </td>
                            <td>
                                <h4>STATUS</h4>
                            </td>
                        </tr>
                        {Array(series)
                            .fill(0)
                            .map((_, index) => (
                                <tr key={`${exc.linkId}@${index}`}>
                                    <td>{index + 1}º</td>
                                    <td>
                                        <ExerciseInput
                                            onChange={onChangeReps}
                                            inputProps={{
                                                max: 99,
                                                min: 0,
                                                placeholder: GetRepsPlaceholder(
                                                    exc.values.reps,
                                                    index
                                                ),
                                                defaultValue: cache.getReps(
                                                    exc.linkId,
                                                    index
                                                ),
                                            }}
                                            hint={RepsHint(index)}
                                            index={index}
                                            exercise={exc.linkId}
                                        />
                                    </td>
                                    <td>
                                        <ExerciseInput
                                            onChange={onChangeWeight}
                                            inputProps={{
                                                max: 999,
                                                min: 0,

                                                defaultValue: cache.getWeight(
                                                    exc.linkId,
                                                    index
                                                ),
                                            }}
                                            hint={WeightHint(index)}
                                            index={index}
                                            exercise={exc.linkId}
                                        />
                                    </td>
                                    <td>{Validate(exc.linkId, index)}</td>
                                </tr>
                            ))}
                    </thead>
                </TrainingSheet>
            ) : (
                <NotStartedDiv>
                    <FiAlertTriangle color="#fff" />
                    <p>Inicie o treino para salvar as cargas!</p>
                </NotStartedDiv>
            )}

            {started && exc.values.interval.option === 0 && (
                <div style={{ width: "100%", marginBlock: 10 }}>
                    <Timer
                        tag={exc.id}
                        maxTime={exc.values.interval.value[0]}
                    />
                </div>
            )}

            {comment && (
                <CommentsView onClick={DismissComment}>
                    <div>
                        <comment.icon size={12} />
                        <p>{comment.title}</p>
                    </div>
                    <p>{comment.subtitle}</p>
                </CommentsView>
            )}

            <Footer>
                {/**/}

                <FooterButton
                    onClick={() =>
                        SetComment({
                            title: "REPETIÇÕES",
                            subtitle: GetMask(exc.values.reps, _reps),
                            icon: FiX,
                        })
                    }
                >
                    <FiX size={16} />
                    <p>REPETIÇÕES</p>
                </FooterButton>

                {exc.values.cadency && (
                    <FooterButton
                        onClick={() =>
                            SetComment({
                                title: "CADÊNCIA",
                                subtitle: GetMask(exc.values.cadency, _cadency),
                                icon: RiSpeedMiniFill,
                            })
                        }
                    >
                        <RiSpeedMiniFill size={16} />
                        <p>CADÊNCIA</p>
                    </FooterButton>
                    /* <Row className="spaced">
                            <p>CADÊNCIA: {}</p>
                            <p>{_cadency.data[cadency.option].description}</p>
                        </Row>*/
                )}
                {exc.values.interval.option > 0 && (
                    <FooterButton
                        onClick={() =>
                            SetComment({
                                title: "INTERVALO",
                                subtitle: GetMask(
                                    exc.values.interval,
                                    _interval
                                ),
                                icon: LuTimer,
                            })
                        }
                    >
                        <LuTimer size={16} />
                        <p>INTERVALO</p>
                    </FooterButton>
                )}
                {exc.values.method.option !== 0 && (
                    <FooterButton
                        onClick={() =>
                            SetComment({
                                title: "MÉTODO",
                                subtitle: GetMask(exc.values.method, _method),
                                icon: SiAlwaysdata,
                            })
                        }
                    >
                        <SiAlwaysdata size={16} />
                        <p>MÉTODO</p>
                    </FooterButton>
                )}
                {exc.obs && (
                    <FooterButton
                        onClick={() =>
                            SetComment({
                                title: "OBSERVAÇÃO",
                                subtitle: exc.obs,
                                icon: AiOutlineExclamation,
                            })
                        }
                    >
                        <AiOutlineExclamation size={16} />
                        <p>OBS</p>
                    </FooterButton>
                )}
            </Footer>
        </div>
    )
}

export default Content
