import React, { ReactNode, useEffect, useState } from "react"
import { Modal } from "src/components/Modal"

import { IoChatboxEllipses } from "react-icons/io5"
import { styled } from "styled-components"

// import { Container } from './styles';

export const AvisoArea = styled.div`
    flex-direction: column;
    align-items: center;
    width: calc(100vw - 80px);
    max-height: calc(100vh - 100px);
    max-width: 400px;

    .title {
        align-items: center;
        padding-bottom: 10px;

        font-size: 14px;
        h4 {
            margin-right: 10px;
        }
    }

    .text {
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }

    p {
        text-align: center;

        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
    }

    span {
        margin: 10px;
    }

    button {
        background-color: var(--primary);
        font-weight: bold;
        padding: 8px;
        cursor: pointer;
        border-radius: 8px;

        font-size: 14px;
    }
`

const ModalAviso: React.FC<{
    isOpen: boolean
    onConfirm: () => void
    onCancel: () => void
    body: ReactNode
}> = ({ isOpen, onConfirm, onCancel, body }) => {
    function Confirm() {
        if (onConfirm) {
            onConfirm()
            onCancel()
        }
    }

    function Cancel() {
        if (onCancel) onCancel()
    }

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={Cancel}>
                    <AvisoArea>
                        <div className="title">
                            <h4>Aviso!</h4>
                        </div>
                        <div className="text">
                            <p>{body}</p>
                        </div>

                        <div>
                            <button
                                style={{ backgroundColor: "#0008" }}
                                onClick={Cancel}
                            >
                                Cancelar
                            </button>
                            <span />
                            <button onClick={Confirm}>Confirmar</button>
                        </div>
                    </AvisoArea>
                </Modal>
            )}
        </>
    )
}

export default ModalAviso
